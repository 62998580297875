import * as React from 'react';
import { FormattedMessage } from '../../localization/index';

const iconEmail = require('../../assets/imgs/icon-email.png');
const iconTel = require('../../assets/imgs/icon-tel.png');
const ccMaster = require('../../assets/imgs/cc-master.png');
const ccVisa = require('../../assets/imgs/cc-visa.png');
const logo = require('../../assets/imgs/logo.svg');

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <h1>
          <FormattedMessage id="footer-h1" defaultMessage="Experience SparkStream on Any Device"></FormattedMessage>
        </h1>
        <div className="logo-wrap">
          <img src={logo} className="logo" />
        </div>
        <div className="footer-wrap">
          <div className="footer-left">
            <div className="customer-care">
              <h3>
                <FormattedMessage id="customer-care" defaultMessage="Customer Care"></FormattedMessage>
              </h3>
              <div className="list-wrap">
                <div className="each">
                  <div className="icon">
                    <img src={iconTel} />
                  </div>
                  <div className="details">
                    <a href="tel:+35777788373">+35777788373</a>
                  </div>
                </div>
                <div className="each">
                  <div className="icon">
                    <img src={iconEmail} />
                  </div>
                  <div className="details">
                    <a href="mailto:help@sparkstream.tv">help@sparkstream.tv</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="company-info">
              <p>
                <strong className="text-gr">AMB GLOBAL DIGITAL SOLUTIONS LTD</strong>
                <br />
                1 Maiou 3, 2610 Agios Epifanios Oreinis, Cyprus
                <br />
                <a href="https://www.ambglobaldigital.com" target="_blank">
                  www.ambglobaldigital.com
                </a>
              </p>
            </div>
            <div className="copyright">
              {/* <script>document.write(new Date().getFullYear());</script> © SparkStream <FormattedMessage id="all-rights-reserved" defaultMessage="All Rights Reserved"></FormattedMessage> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="quick-links">
            <a className="each" href="https://portal.sparkstream.tv/privacy-policy" target="_blank">
              <FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.sparkstream.tv/refund-policy" target="_blank">
              <FormattedMessage id="refund-policy" defaultMessage="Refund Policy"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.sparkstream.tv/terms-and-conditions" target="_blank">
              <FormattedMessage id="terms-conditions" defaultMessage="Terms & Conditions"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.sparkstream.tv/faq" target="_blank">
              <FormattedMessage id="faq" defaultMessage="FAQ"></FormattedMessage>
            </a>
          </div>
          <div className="credit-card-icon">
            <img src={ccMaster} />
            <img src={ccVisa} />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
